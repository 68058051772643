import { Box, Flex, Heading, HStack, Spacer, Stack, Text, Tooltip } from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import { ArrowLeft } from 'phosphor-react';
import React, { SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { WarningIconWithTooltip } from '../../../assets/warning';
import { weiToDisplayAmt } from '../../../data-lib/ethereum';
import { intToDate } from '../../../data-lib/helpers';
import { ChainId, NETWORKS, TokenInfo } from '../../../data-lib/networks';
import { Build8949Response, OutGroupingDto, SalesLotDto, TaxLotDto, Token8949ReportDto } from '../../../hooks/useExternalTransactionsApi';
import { usePagination } from '../../../hooks/usePagination';
import { useTokenRepo } from '../../../hooks/useTokenRepo';
import { TokenSafetyContext, useTokenSafety } from '../../../hooks/useTokenSafety';
import { toDateDisplay, toUSD } from '../../../tools/common';
import { ContactNameOrAddress } from '../../base/address-label';
import { PageSelector } from '../../display/claim-table';
import { ResponsiveStack } from '../../display/responsive-stack';
import { TokenMultiselect } from '../../display/token-multiselect';
import { TabOptions, TabSwitcher } from '../../display/views/account-tag-view';
import { checkDate, TableFilters } from '../../display/views/filters/claim-filter';
import { ClearFilterPillsStack, DateSelect, PillProps, SetDate, TableFilter } from '../../display/views/filters/common';
import { SecondaryButton } from '../../inputs/buttons';
import { ColumnDefinition, ListItemProps, ListViewCard, ShadowBox } from '../../layout/cards';
import { MaxWidthWrapper, SummaryPanel } from '../../layout/page-layout';
import { isTcsToken } from '../common';
import { _8949ExportModal } from './export-modal';
import { TRANSACTION_IN_HEADERS, TRANSACTION_MATCHING_HEADERS, TRANSACTION_OUT_HEADERS, TRANSFER_HEADERS } from './headers';
import { transferRows } from './mock-data';
import { _8949Tab, _8949Tabs, get8949TypeDisplayName } from './tabs-type';

type DomainSalesLot = Omit<SalesLotDto, 'timestamp' | 'token'> & { timestamp: Date; token: TokenInfo; displayAmount: number };
type DomainTaxLot = Omit<TaxLotDto, 'timestamp' | 'token'> & { timestamp: Date; token: TokenInfo; displayAmount: number };

type DomainInTransfer = {
    transfer: DomainTaxLot;
    proceeds: string | null;
    cost: string | null;
    gainLoss: string | null;
    afterInBalance: BigNumber;
    afterOutBalance: BigNumber;
    usedAmount: BigNumber;
};
type DomainOutGrouping = { transfer: DomainSalesLot; ins: DomainInTransfer[] };

export type DomainToken8949Report = {
    ins: DomainTaxLot[];
    outs: DomainSalesLot[];
    token: TokenInfo;
    chainId: number;
    outGroupings: DomainOutGrouping[] | null;
};

const exportFilterToPills = (setFilters: React.Dispatch<SetStateAction<ExportFilterValues>>) => (filters: ExportFilterValues) => {
    const dateFilterPills: PillProps[] = [
        ...(!!filters.date.startDate
            ? [
                  {
                      label: `From: ${toDateDisplay(filters.date.startDate)}`,
                      clear: () => setFilters(filters => ({ ...filters, date: { ...filters.date, startDate: undefined } })),
                  },
              ]
            : []),
        ...(!!filters.date.endDate
            ? [
                  {
                      label: `To: ${toDateDisplay(filters.date.endDate)}`,
                      clear: () => setFilters(filters => ({ ...filters, date: { ...filters.date, endDate: undefined } })),
                  },
              ]
            : []),
    ];

    const tokenPills: PillProps[] = [...filters.selectedTokenSymbols].map(symbol => ({
        label: symbol,
        clear: () =>
            setFilters(filters => {
                const selectedTokenSymbols = new Set([...filters.selectedTokenSymbols]);
                selectedTokenSymbols.delete(symbol);
                return { ...filters, selectedTokenSymbols };
            }),
    }));

    return [...dateFilterPills, ...tokenPills];
};

type ExportFilterValues = {
    date: { startDate?: Date; endDate?: Date };
    selectedTokenSymbols: Set<string>;
};

const initialExportFilterValues = (): ExportFilterValues => {
    const now = new Date();
    const year = now.getFullYear();
    const yearToDisplay = now.getMonth() >= 5 ? year : year - 1;
    return {
        date: { startDate: new Date(yearToDisplay, 0, 1), endDate: new Date(yearToDisplay, 11, 31) },
        selectedTokenSymbols: new Set(),
    };
};

const getPathnameFor8949Report = (tab: _8949Tab) => {
    return {
        pathname: '/8949-wizard',
        search: `?tab=${tab}`,
    };
};

type SetFilter = (key: keyof ExportFilterValues, value: ExportFilterValues[keyof ExportFilterValues]) => void;

type FilterProps = {
    filters: ExportFilterValues;
    setFilters: React.Dispatch<SetStateAction<ExportFilterValues>>;
    selectableTokenSymbols: Set<string>;
};

const ExportFilter = ({ setFilters, filters, selectableTokenSymbols }: FilterProps) => {
    const setFilter: SetFilter = (key, value) => setFilters(filters => ({ ...filters, [key]: value }));
    const setDate: SetDate = (type, date) => setFilters(filters => ({ ...filters, date: { ...filters.date, [type]: date } }));
    const resetDates = () => setFilters(filters => ({ ...filters, date: {} }));

    return (
        <Box w="100%" pb="4">
            <ResponsiveStack>
                <DateSelect
                    startDate={filters.date.startDate}
                    endDate={filters.date.endDate}
                    setDate={setDate}
                    resetDates={resetDates}
                    paymentTimestamps={[]}
                />
                <TokenMultiselect
                    seletectableTokenSymbols={selectableTokenSymbols}
                    selectedTokenSymbols={filters.selectedTokenSymbols}
                    toggleTokenSymbol={_symbol => {
                        const symbol = _symbol.toUpperCase();
                        return setFilter(
                            'selectedTokenSymbols',
                            filters.selectedTokenSymbols.has(symbol)
                                ? (() => {
                                      filters.selectedTokenSymbols.delete(symbol);
                                      return filters.selectedTokenSymbols;
                                  })()
                                : filters.selectedTokenSymbols.add(symbol),
                        );
                    }}
                />
            </ResponsiveStack>
        </Box>
    );
};

const getHeaderForActiveTab = (activeTab: _8949Tab): (string | ColumnDefinition)[] => {
    switch (activeTab) {
        case 'transactionsIn':
            return TRANSACTION_IN_HEADERS;
        case 'transactionsOut':
            return TRANSACTION_OUT_HEADERS;
        case 'transactionMatching':
            return TRANSACTION_MATCHING_HEADERS;
        case 'transfers':
            return TRANSFER_HEADERS;
    }
};

const buildExportFilters = (filterValues: ExportFilterValues): TableFilters<DomainSalesLot | DomainTaxLot> => {
    const {
        date: { startDate, endDate },
        selectedTokenSymbols,
    } = filterValues;
    const filterByDate =
        !startDate && !endDate ? undefined : ({ timestamp }: DomainSalesLot | DomainTaxLot) => checkDate(timestamp, startDate, endDate);

    const filterByToken =
        selectedTokenSymbols.size == 0
            ? undefined
            : (payment: DomainSalesLot | DomainTaxLot) => selectedTokenSymbols.has(payment.token.token.symbol.toUpperCase());

    return [filterByDate, filterByToken].filter((x): x is TableFilter<DomainSalesLot | DomainTaxLot> => x !== undefined);
};

type SummaryColProps = { label: string; value: number };
const SummaryCol = ({ value, label }: SummaryColProps) => {
    return (
        <Box>
            <Text fontWeight={'600'} fontSize="14px">
                {label}
            </Text>
            <Text fontWeight={700} fontSize="20px">
                {value.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }) || '-'}
            </Text>
        </Box>
    );
};

const inOrOutToRow = (entry: DomainSalesLot | DomainTaxLot, isTokenInfoSafe: TokenSafetyContext['isTokenInfoSafe']): ListItemProps => {
    const chainId = entry.chainId as ChainId;
    const isTokenSafe = isTokenInfoSafe(entry.token);

    return {
        columnValues: [
            <Text noOfLines={1} whiteSpace={'nowrap'}>
                {toDateDisplay(entry.timestamp)}
            </Text>,
            NETWORKS[chainId].label,
            <ContactNameOrAddress chainId={chainId}>{entry.from}</ContactNameOrAddress>,
            <ContactNameOrAddress chainId={chainId}>{entry.to}</ContactNameOrAddress>,
            <Tooltip label={entry.token.token.symbol}>
                <HStack>
                    {!isTokenSafe && (
                        <WarningIconWithTooltip
                            label="Beware of using airdropped scam tokens."
                            warningOverrides={{ color: 'red', w: '14px', h: '14px' }}
                        />
                    )}
                    <Text noOfLines={1} textOverflow={'ellipsis'} fontWeight={'bold'}>
                        {entry.token.token.symbol}
                    </Text>
                </HStack>
            </Tooltip>,
            toUSD(entry.displayAmount, true).replace('$', ''),
            entry.usdMark !== null ? `$${entry.usdMark}` : 'N/A',
            entry.usdValue !== null ? `$${entry.usdValue}` : 'N/A',
            entry.description,
            entry.txHash,
        ],
        isUnsafe: !isTokenSafe,
    };
};

const toMatchesRow = (outGrouping: DomainOutGrouping, isTokenInfoSafe: TokenSafetyContext['isTokenInfoSafe']): ListItemProps[] => {
    const chainId = outGrouping.transfer.chainId as ChainId;
    const isTokenSafe = isTokenInfoSafe(outGrouping.transfer.token);

    return outGrouping.ins.map(inTransfer => {
        return {
            columnValues: [
                <ContactNameOrAddress chainId={chainId} excludeYouBadge>
                    {outGrouping.transfer.from}
                </ContactNameOrAddress>,
                NETWORKS[chainId].label,
                outGrouping.transfer.token.token.symbol,
                weiToDisplayAmt({ amountWei: inTransfer.usedAmount, decimals: outGrouping.transfer.token.token.decimals }),
                toDateDisplay(inTransfer.transfer.timestamp),
                toDateDisplay(outGrouping.transfer.timestamp),
                inTransfer.proceeds?.toString() ?? 'N/A',
                inTransfer.cost?.toString() ?? 'N/A',
                inTransfer.gainLoss?.toString() ?? 'N/A',
                outGrouping.transfer.lotNumber,
                inTransfer.transfer.lotNumber,
                outGrouping.transfer.usdMark ?? 'N/A',
                inTransfer.transfer.usdValue ?? 'N/A',
                weiToDisplayAmt({ amountWei: inTransfer.afterInBalance, decimals: outGrouping.transfer.token.token.decimals }),
                outGrouping.transfer.usdValue ?? 'N/A',
                weiToDisplayAmt({ amountWei: inTransfer.afterOutBalance, decimals: outGrouping.transfer.token.token.decimals }),
                outGrouping.transfer.txHash,
                inTransfer.transfer.txHash,
            ],
            isUnsafe: !isTokenSafe,
        };
    });
};

const inDtoToDomain = (token: TokenInfo, x: TaxLotDto): DomainTaxLot => {
    const timestamp = intToDate(x.timestamp);

    const displayAmount = weiToDisplayAmt({ amountWei: BigNumber.from(x.amountWei), decimals: token.token.decimals });
    const isTcs = isTcsToken(x.chainId, x.token.address);

    return {
        ...x,
        timestamp,
        displayAmount,
        token,
        usdMark: isTcs ? '0.1' : x.usdMark,
        usdValue: isTcs ? (displayAmount * 0.1).toString() : x.usdValue,
    };
};

const outDtoToDomain = (token: TokenInfo, x: SalesLotDto): DomainSalesLot => {
    const timestamp = intToDate(x.timestamp);

    const displayAmount = weiToDisplayAmt({ amountWei: BigNumber.from(x.amountWei), decimals: token.token.decimals });
    const isTcs = isTcsToken(x.chainId, x.token.address);

    return {
        ...x,
        timestamp,
        displayAmount,
        token,
        usdMark: isTcs ? '0.1' : x.usdMark,
        usdValue: isTcs ? (displayAmount * 0.1).toString() : x.usdValue,
    };
};

const outGroupingToDomain =
    (token: TokenInfo) =>
    (x: OutGroupingDto): DomainOutGrouping => {
        const transfer = outDtoToDomain(token, x.transfer);
        const ins = x.ins.map(
            (inTransfer): DomainInTransfer => ({
                transfer: inDtoToDomain(token, inTransfer.transfer),
                usedAmount: BigNumber.from(inTransfer.usedAmount),
                afterInBalance: BigNumber.from(inTransfer.afterInBalance),
                afterOutBalance: BigNumber.from(inTransfer.afterOutBalance),
                proceeds: inTransfer.proceeds?.toString() ?? null,
                cost: inTransfer.cost?.toString() ?? null,
                gainLoss: inTransfer.gainLoss?.toString() ?? null,
            }),
        );

        return { transfer, ins };
    };

const toDomain =
    (getTokenByChainIdAndAddress: (chainId: ChainId) => (address: string) => TokenInfo) =>
    (report: Token8949ReportDto): DomainToken8949Report[] => {
        const token = getTokenByChainIdAndAddress(report.chainId as ChainId)(report.token.address);
        if (!token) return [];

        const ins = report.ins.map(x => inDtoToDomain(token, x));
        const outs = report.outs.map(x => outDtoToDomain(token, x));
        const outGroupings = report.outGroupings ? report.outGroupings.map(outGroupingToDomain(token)) : null;

        return [{ ins, outs, token, chainId: report.chainId, outGroupings }];
    };

type _8949SuccessCardProps = { data: Build8949Response; reportName: string };
export const _8949SuccessCard = ({ data: { reports: data, transfers }, reportName }: _8949SuccessCardProps) => {
    const navigate = useNavigate();
    const _8949TabOptions: TabOptions<_8949Tab>[] = _8949Tabs.map(tab => ({
        label: get8949TypeDisplayName(tab),
        value: tab,
    }));
    const setTab = (tab: _8949Tab) => {
        setActiveTab(tab);
        navigate(getPathnameFor8949Report(tab), { replace: true });
    };
    const [activeTab, setActiveTab] = useState<_8949Tab>(_8949Tabs[0]);
    const { getTokenByChainIdAndAddress } = useTokenRepo();
    const [filters, setFilters] = React.useState<ExportFilterValues>({
        date: { startDate: undefined, endDate: undefined },
        selectedTokenSymbols: new Set(),
    });
    const { isTokenInfoSafe } = useTokenSafety();

    React.useEffect(() => {
        setFilters(initialExportFilterValues());
    }, []);

    const aggregatedFilter = React.useMemo(() => buildExportFilters(filters), [filters]);

    const domainReports = React.useMemo(() => data.map(toDomain(getTokenByChainIdAndAddress)).flat(), [data]);

    const ins = React.useMemo(
        () => domainReports.flatMap(x => x.ins).sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime()),
        [domainReports],
    );
    const outs = React.useMemo(
        () => domainReports.flatMap(x => x.outs).sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime()),
        [domainReports],
    );

    const selectableTokenSymbols = React.useMemo(
        () => new Set([...domainReports.map(x => x.token.token.symbol), ...transfers.map(x => x.token.symbol)]),
        [domainReports, transfers],
    );

    const groupings = React.useMemo(
        () =>
            domainReports
                .flatMap(x => x.outGroupings)
                .filter((x): x is DomainOutGrouping => x !== null)
                .sort((a, b) => a.transfer.timestamp.getTime() - b.transfer.timestamp.getTime()),
        [domainReports],
    );

    const filteredIns = React.useMemo(
        () => aggregatedFilter.reduce((entries, filterFunc) => entries.filter(filterFunc), ins),
        [ins, aggregatedFilter],
    );

    const filteredOuts = React.useMemo(
        () => aggregatedFilter.reduce((entries, filterFunc) => entries.filter(filterFunc), outs),
        [outs, aggregatedFilter],
    );

    const filteredGroupings = React.useMemo(
        () => aggregatedFilter.reduce((entries, filterFunc) => entries.filter(x => filterFunc(x.transfer)), groupings),
        [groupings, aggregatedFilter],
    );

    const filteredInRows = React.useMemo(() => filteredIns.map(entry => inOrOutToRow(entry, isTokenInfoSafe)), [filteredIns]);
    const filteredOutRows = React.useMemo(() => filteredOuts.map(entry => inOrOutToRow(entry, isTokenInfoSafe)), [filteredOuts]);
    const filteredMatchingRows = React.useMemo(() => filteredGroupings.flatMap(x => toMatchesRow(x, isTokenInfoSafe)), [filteredGroupings]);

    const rows = React.useMemo(() => {
        switch (activeTab) {
            case 'transactionsIn':
                return filteredInRows;
            case 'transactionsOut':
                return filteredOutRows;
            case 'transactionMatching':
                return filteredMatchingRows;
            case 'transfers':
                return transferRows;
        }
    }, [activeTab, filteredInRows, filteredOutRows, filteredMatchingRows]);

    const pageSelectorOptions = usePagination(rows, 10);
    const rowsToDisplay = pageSelectorOptions.shownItems;

    const getSummaryColsForActiveTab = React.useCallback(
        (activeTab: _8949Tab) => {
            switch (activeTab) {
                case 'transactionsIn':
                    return [{ label: 'COST', value: filteredIns.map(x => (x.usdValue ? +x.usdValue : 0)).reduce((a, b) => a + b, 0) }];
                case 'transactionsOut':
                    return [
                        {
                            label: 'PROCEEDS SALE',
                            value: filteredOuts.map(x => (x.usdValue ? +x.usdValue : 0)).reduce((a, b) => a + b, 0),
                        },
                    ];
                case 'transactionMatching':
                    return [
                        {
                            label: 'PROCEEDS SALE',
                            value: filteredMatchingRows
                                .map(x => (isNaN(Number(x.columnValues[6])) ? 0 : +(x.columnValues[6] ?? 0)))
                                .reduce((a, b) => a + b, 0),
                        },
                        {
                            label: 'COST',
                            value: filteredMatchingRows
                                .map(x => (isNaN(Number(x.columnValues[7])) ? 0 : +(x.columnValues[7] ?? 0)))
                                .reduce((a, b) => a + b, 0),
                        },
                        {
                            label: 'GAIN/LOSS',
                            value: filteredMatchingRows
                                .map(x => (isNaN(Number(x.columnValues[8])) ? 0 : +(x.columnValues[8] ?? 0)))
                                .reduce((a, b) => a + b, 0),
                        },
                    ];
                case 'transfers':
                    return [];
            }
        },
        [filteredIns, filteredOuts, filteredMatchingRows],
    );

    return (
        <Flex p="12" direction="column" flex="1">
            <SummaryPanel>
                <HStack cursor="pointer" onClick={() => navigate('/reporting')} mb="6" color="gray.600">
                    <ArrowLeft size={18} weight="bold" />
                    <Text fontWeight="semibold" fontSize="md">
                        Back to Reporting Explorer
                    </Text>
                </HStack>
                <Flex>
                    <Heading textStyle="bullaViewHeader">8949 Report</Heading>
                    <Spacer />
                    <_8949ExportModal
                        triggerElement={onClick => (
                            <SecondaryButton onClick={onClick} h="10" px="6">
                                Export
                            </SecondaryButton>
                        )}
                    />
                </Flex>
            </SummaryPanel>
            <MaxWidthWrapper display={'flex'} flexDirection="column" flex="1">
                <Stack pos="relative" spacing="0" overflow={'auto'} mb="8">
                    <HStack mt="4" alignItems="flex-end">
                        <TabSwitcher tab={activeTab} setTab={setTab} options={_8949TabOptions} activeColor="brand.bulla_blue" />
                    </HStack>
                </Stack>
                <ExportFilter filters={filters} setFilters={setFilters} selectableTokenSymbols={selectableTokenSymbols} />
                <ClearFilterPillsStack
                    clearAll={() => setFilters({ date: { startDate: undefined, endDate: undefined }, selectedTokenSymbols: new Set() })}
                    filters={filters}
                    filtersToPills={exportFilterToPills(setFilters)}
                />
                <ShadowBox my="8" px="6" py="8" display={'flex'} flexDirection={'row'} alignItems="center">
                    <Heading size="md" h="fit-content">
                        Summary
                    </Heading>
                    <Spacer />
                    <Stack direction="row" spacing={4}>
                        {getSummaryColsForActiveTab(activeTab).map((x, i) => (
                            <SummaryCol key={i} label={x.label} value={x.value} />
                        ))}
                    </Stack>
                </ShadowBox>
                <ListViewCard
                    headers={getHeaderForActiveTab(activeTab)}
                    displayedListItems={rowsToDisplay}
                    emptyMessage="No data available"
                    alternateRowColor="#F4F6F9"
                    showOverflowX
                />
                <PageSelector {...pageSelectorOptions} pt="6" />
            </MaxWidthWrapper>
        </Flex>
    );
};
