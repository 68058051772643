import {
    AMOUNT_COLUMN_WIDTH,
    CHAIN_COLUMN_WIDTH,
    DATE_COLUMN_WIDTH,
    DESCRIPTION_COLUMN_WIDTH,
    FROM_TO_COLUMN_WIDTH,
    ListViewCardProps,
    TOKEN_COLUMN_WIDTH,
    USD_MARK_COLUMN_WIDTH,
} from '../../layout/cards';

export const TRANSACTION_IN_HEADERS: ListViewCardProps['headers'] = [
    { label: 'date received', relativeColumnWidth: DATE_COLUMN_WIDTH },
    { label: 'chain', relativeColumnWidth: CHAIN_COLUMN_WIDTH },
    { label: 'from', relativeColumnWidth: FROM_TO_COLUMN_WIDTH },
    { label: 'to', relativeColumnWidth: FROM_TO_COLUMN_WIDTH },
    { label: 'token', relativeColumnWidth: TOKEN_COLUMN_WIDTH },
    { label: 'amount', relativeColumnWidth: AMOUNT_COLUMN_WIDTH },
    { label: 'usd value', relativeColumnWidth: USD_MARK_COLUMN_WIDTH },
    { label: 'usd mark', relativeColumnWidth: USD_MARK_COLUMN_WIDTH },
    { label: 'description', relativeColumnWidth: DESCRIPTION_COLUMN_WIDTH },
    { label: 'transaction hash' },
];

export const TRANSACTION_OUT_HEADERS: ListViewCardProps['headers'] = [
    { label: 'date sent', relativeColumnWidth: DATE_COLUMN_WIDTH },
    { label: 'chain', relativeColumnWidth: CHAIN_COLUMN_WIDTH },
    { label: 'from', relativeColumnWidth: FROM_TO_COLUMN_WIDTH },
    { label: 'to', relativeColumnWidth: FROM_TO_COLUMN_WIDTH },
    { label: 'token', relativeColumnWidth: TOKEN_COLUMN_WIDTH },
    { label: 'amount', relativeColumnWidth: AMOUNT_COLUMN_WIDTH },
    { label: 'usd value', relativeColumnWidth: USD_MARK_COLUMN_WIDTH },
    { label: 'usd mark', relativeColumnWidth: USD_MARK_COLUMN_WIDTH },
    { label: 'description', relativeColumnWidth: DESCRIPTION_COLUMN_WIDTH },
    { label: 'transaction hash' },
];

export const TRANSACTION_MATCHING_HEADERS: ListViewCardProps['headers'] = [
    { label: 'wallet' },
    { label: 'chain', relativeColumnWidth: CHAIN_COLUMN_WIDTH },
    { label: 'token', relativeColumnWidth: TOKEN_COLUMN_WIDTH },
    { label: 'amount', relativeColumnWidth: AMOUNT_COLUMN_WIDTH },
    { label: 'date acquired', relativeColumnWidth: DATE_COLUMN_WIDTH },
    { label: 'date sold', relativeColumnWidth: DATE_COLUMN_WIDTH },
    { label: 'proceeds sale', relativeColumnWidth: AMOUNT_COLUMN_WIDTH },
    { label: 'cost', relativeColumnWidth: AMOUNT_COLUMN_WIDTH },
    { label: 'gain/loss', relativeColumnWidth: AMOUNT_COLUMN_WIDTH },
    { label: 'sales lot', relativeColumnWidth: AMOUNT_COLUMN_WIDTH },
    { label: 'tax lot', relativeColumnWidth: AMOUNT_COLUMN_WIDTH },
    { label: 'sold at', relativeColumnWidth: DATE_COLUMN_WIDTH },
    { label: 'tax lot total', relativeColumnWidth: AMOUNT_COLUMN_WIDTH },
    { label: 'tax balance', relativeColumnWidth: AMOUNT_COLUMN_WIDTH },
    { label: 'sales lot total', relativeColumnWidth: AMOUNT_COLUMN_WIDTH },
    { label: 'sales balance', relativeColumnWidth: AMOUNT_COLUMN_WIDTH },
    { label: 'sales lot txn', relativeColumnWidth: AMOUNT_COLUMN_WIDTH },
    { label: 'tax lot txn' },
];

export const TRANSFER_HEADERS: ListViewCardProps['headers'] = [
    { label: 'tax date', relativeColumnWidth: DATE_COLUMN_WIDTH },
    { label: 'transfer date', relativeColumnWidth: DATE_COLUMN_WIDTH },
    { label: 'chain', relativeColumnWidth: CHAIN_COLUMN_WIDTH },
    { label: 'token id', relativeColumnWidth: TOKEN_COLUMN_WIDTH },
    { label: 'token amt', relativeColumnWidth: AMOUNT_COLUMN_WIDTH },
    { label: 'from', relativeColumnWidth: FROM_TO_COLUMN_WIDTH },
    { label: 'to', relativeColumnWidth: FROM_TO_COLUMN_WIDTH },
    { label: 'tax basis', relativeColumnWidth: AMOUNT_COLUMN_WIDTH },
    { label: 'sales txn', relativeColumnWidth: AMOUNT_COLUMN_WIDTH },
    { label: 'tax chain', relativeColumnWidth: CHAIN_COLUMN_WIDTH },
    { label: 'tax txn' },
];
