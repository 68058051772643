import axios from 'axios';
import { endpoints } from '../tools/common';
import { useGnosisSafe } from '../state/gnosis-state';
import { useWeb3 } from './useWeb3';
import React, { useContext, useState } from 'react';
import { useActingWalletAddress } from './useWalletAddress';

export interface LinkInformation {
    link: string;
    linkId: string;
    attachment?: string;
}

const LinksContext = React.createContext<{ links: LinkInformation[] | null; isLoading: boolean; refetch: () => Promise<void> }>({
    links: null,
    isLoading: false,
    refetch: () => Promise.resolve(),
});

export function LinksProvider({ children }: { children: React.ReactNode }) {
    const [links, setLinks] = useState<LinkInformation[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const { fetchLinks } = useLinksApi();
    const actingWallet = useActingWalletAddress();

    React.useEffect(() => {
        const loadLinks = async () => {
            setIsLoading(true);
            try {
                const data = await fetchLinks();
                setLinks(data);
            } catch (error) {
                console.error('Failed to fetch links:', error);
                setLinks([]);
            } finally {
                setIsLoading(false);
            }
        };

        loadLinks();
    }, [actingWallet]);

    const refetch = React.useCallback(async () => {
        setIsLoading(true);
        try {
            const data = await fetchLinks();
            setLinks(data);
        } catch (error) {
            console.error('Failed to fetch links:', error);
        } finally {
            setIsLoading(false);
        }
    }, [fetchLinks]);

    React.useEffect(() => {
        refetch();
    }, [actingWallet]);

    return <LinksContext.Provider value={{ links, isLoading, refetch }}>{children}</LinksContext.Provider>;
}

export const useLinks = () => {
    const context = useContext(LinksContext);
    if (context.links === null) {
        throw new Error('useLinks must be used within a LinksProvider');
    }
    return { links: context.links, isLoading: context.isLoading, refetch: context.refetch };
};

export const useLinksApi = () => {
    const { connectedSafeAddress } = useGnosisSafe();
    const { connectedNetwork, userAddress } = useWeb3();

    const gnosisSafeQueryArgs = !!connectedSafeAddress ? `?account_type=gnosis&chain_id=${connectedNetwork}` : '';
    const actingWallet = !!connectedSafeAddress ? connectedSafeAddress : userAddress;

    const fetchLinks = async (): Promise<LinkInformation[]> => {
        try {
            const { data } = await axios.get(`${endpoints.settingsApi}/get-links/${actingWallet}${gnosisSafeQueryArgs}`, {
                withCredentials: true,
            });
            return data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 401) {
                    throw new Error('Authentication failed');
                }
                throw new Error('Server error occurred. Please try again later');
            }
            throw new Error('Failed to fetch links');
        }
    };

    const saveLink = async (link: string, attachment?: string): Promise<boolean> => {
        const payload = { link, attachment };

        try {
            const { status } = await axios.post(`${endpoints.settingsApi}/upload-link/${actingWallet}${gnosisSafeQueryArgs}`, payload, {
                withCredentials: true,
                headers: { 'content-type': 'application/json' },
            });

            return status === 200;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 400) {
                    throw new Error(error.response.data.message || 'Invalid link data');
                } else if (error.response?.status === 401) {
                    throw new Error('Authentication failed');
                }
                throw new Error('Server error occurred. Please try again later');
            }
            throw new Error('Failed to save link data');
        }
    };

    return { fetchLinks, saveLink };
};
