import { Box, Grid, HStack, ModalBody, Stack, Text, useBoolean } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { ImportedExternalTransactionInfo } from '../../../data-lib/data-model';
import { getItemRelationToUser } from '../../../data-lib/helpers';
import { NETWORKS } from '../../../data-lib/networks';
import { TokenDisplay } from '../../../data-lib/tokens';
import { useEditNotesDisplay } from '../../../hooks/useEditNotesDisplay';
import { useExternalTransactionsApi } from '../../../hooks/useExternalTransactionsApi';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { useDataReadiness } from '../../../hooks/useUserData';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { toDateWithTime } from '../../../tools/common';
import { TXHashLabel } from '../../base/address-label';
import { WithSkeleton } from '../../base/skeleton';
import { getStatusBadge } from '../../base/status-badge';
import { useBullaToast } from '../../base/toast';
import { ChainSymbol } from '../../chain-symbol';
import { TokenAmount } from '../../currency/token-display-amount';
import { ViewTagOnItem } from '../../inputs/account-tag-input';
import { CloseModalButton } from '../common';
import { PremiumPricingModal } from '../premium-pricing-modal';
import { claimDetailVariants, CreditorDebtorBox, ItemDesc, ReceiptLine } from './item-details-components';

export type ExternalTransactionDetailsProps = {
    externalTransaction?: ImportedExternalTransactionInfo;
    handleClose: () => void;
    modalContentRef?: React.RefObject<HTMLDivElement>;
};

export const ExternalTransactionDetails = ({ externalTransaction, handleClose, modalContentRef }: ExternalTransactionDetailsProps) => {
    const userAddress = useActingWalletAddress();
    const { isChainInitialized } = useDataReadiness();
    const isMobile = useIsMobile();
    const isLoading = !externalTransaction || !isChainInitialized(externalTransaction.chainId);
    const [notesOnServer, setNotesOnServer] = React.useState(externalTransaction?.notes ?? '');
    const [tagsOnServer, setTagsOnServer] = React.useState(externalTransaction?.tags ?? []);
    const { saveExternalTransactions } = useExternalTransactionsApi();
    const triggerToast = useBullaToast();
    const [savingTags, setSavingTags] = useBoolean();
    const { blockExplorer } = NETWORKS[externalTransaction?.chainId!];

    const { labelRow, notesRow, modalOpen, closeModal } = useEditNotesDisplay({
        isLoading,
        item: !!externalTransaction ? { ...externalTransaction, initialNotes: externalTransaction.notes, tags: tagsOnServer } : undefined,
        onSuccess: setNotesOnServer,
    });

    React.useEffect(() => {
        if (externalTransaction) {
            setTagsOnServer(externalTransaction.tags);
            setNotesOnServer(externalTransaction.notes);
        }
    }, [!!externalTransaction]);

    const bodyRef = React.useRef<HTMLDivElement>(null);

    const templateColumns = isMobile ? '1fr 1fr' : '1fr 1fr 1fr';

    return (
        <>
            <CloseModalButton onClose={handleClose} zIndex={'modal'} />
            <PremiumPricingModal modalOpen={modalOpen} closeModal={closeModal} />

            <ModalBody pb={10} pt={4} ref={bodyRef} px="0" overflowY="auto" overflowX={'hidden'}>
                <Box width="200%">
                    <AnimatePresence initial={false}>
                        <motion.div
                            key="details"
                            style={{
                                width: `50%`,
                                display: 'inline-block',
                                float: 'left',
                                padding: '0 2em',
                                maxHeight: 'fit-content',
                                height: '100%',
                            }}
                            initial={'active'}
                            animate={'active'}
                            transition={{
                                x: { type: 'just' },
                            }}
                            variants={claimDetailVariants}
                        >
                            <WithSkeleton isLoading={isLoading} fixedWidth="16em" height="32px">
                                <Text color="gray.700" fontWeight={'700'} fontSize="24px" noOfLines={1} lineHeight="32px">
                                    Transaction Details
                                </Text>
                            </WithSkeleton>

                            <Box h="16px" />
                            <Stack spacing="3">
                                <ItemDesc title="Chain">
                                    <WithSkeleton isLoading={isLoading} fixedWidth="10em">
                                        {externalTransaction && (
                                            <HStack>
                                                <ChainSymbol chainId={externalTransaction.chainId} />
                                                <Text>{NETWORKS[externalTransaction.chainId].label}</Text>
                                            </HStack>
                                        )}
                                    </WithSkeleton>
                                </ItemDesc>
                                <ItemDesc title="Token">
                                    <WithSkeleton isLoading={isLoading} fixedWidth="10em">
                                        {externalTransaction && <TokenDisplay token={externalTransaction.tokenInfo} />}
                                    </WithSkeleton>
                                </ItemDesc>
                                <ItemDesc title="Date">
                                    <WithSkeleton isLoading={isLoading} fixedWidth="10em">
                                        {externalTransaction && toDateWithTime(externalTransaction.created)}
                                    </WithSkeleton>
                                </ItemDesc>
                                <ItemDesc title="Tx Hash">
                                    <WithSkeleton isLoading={isLoading} fixedWidth="6em">
                                        <HStack>
                                            {externalTransaction ? (
                                                <>
                                                    <TXHashLabel
                                                        fontWeight={700}
                                                        blockExplorerURL={blockExplorer}
                                                        txHash={externalTransaction.txHash}
                                                        as="b"
                                                    />
                                                </>
                                            ) : (
                                                'loading'
                                            )}
                                        </HStack>
                                    </WithSkeleton>
                                </ItemDesc>
                                <ItemDesc title="Description">
                                    <WithSkeleton
                                        children={externalTransaction ? externalTransaction.description : 'loading'}
                                        isLoading={isLoading}
                                        randomW
                                    />
                                </ItemDesc>
                                <ItemDesc title="Categories">
                                    <WithSkeleton isLoading={isLoading} fixedWidth="14em">
                                        {externalTransaction ? (
                                            <ViewTagOnItem
                                                item={externalTransaction}
                                                isDisabled={savingTags || !externalTransaction}
                                                isLoading={savingTags}
                                                saveTagsOverride={async newTags => {
                                                    if (!externalTransaction) return;

                                                    setSavingTags.on();

                                                    return saveExternalTransactions({
                                                        [externalTransaction.id]: {
                                                            id: externalTransaction.id,
                                                            tags: newTags,
                                                            notes: notesOnServer,
                                                            description: externalTransaction.description,
                                                        },
                                                    })
                                                        .then(() => {
                                                            triggerToast({
                                                                title: 'Tags saves successfully.',
                                                            });
                                                            setTagsOnServer(newTags);
                                                        })
                                                        .catch(e => {
                                                            console.error(e);
                                                            triggerToast({
                                                                title: 'Error saving tags. Please try again later.',
                                                                status: 'error',
                                                            });
                                                        })
                                                        .finally(() => {
                                                            setSavingTags.off();
                                                        });
                                                }}
                                                dropdownPortalRef={modalContentRef}
                                            />
                                        ) : (
                                            'loading'
                                        )}
                                    </WithSkeleton>
                                </ItemDesc>
                                <ItemDesc title="Status">
                                    <WithSkeleton
                                        children={
                                            externalTransaction
                                                ? getStatusBadge('Paid', getItemRelationToUser(userAddress, externalTransaction).direction)
                                                : ''
                                        }
                                        isLoading={isLoading}
                                        randomW
                                    />
                                </ItemDesc>
                            </Stack>
                            <Box h="2" />
                            <CreditorDebtorBox
                                maxW="380px"
                                creditor={externalTransaction?.creditor ?? 'loading'}
                                debtor={externalTransaction?.debtor ?? 'loading'}
                                creditorLabel="To"
                                debtorLabel="From"
                                chainId={externalTransaction?.chainId ?? 1}
                            />
                            {labelRow}
                            {notesRow}
                            <Grid templateColumns={templateColumns} w={'100%'}>
                                <ReceiptLine
                                    tokenAmount={
                                        <WithSkeleton isLoading={isLoading}>
                                            {externalTransaction && (
                                                <TokenAmount
                                                    amount={externalTransaction.paidAmount}
                                                    tokenInfo={externalTransaction.tokenInfo}
                                                />
                                            )}
                                        </WithSkeleton>
                                    }
                                    title="Paid Amount"
                                    isMobile={!!isMobile}
                                    isLoading={isLoading}
                                />
                            </Grid>
                        </motion.div>
                    </AnimatePresence>
                </Box>
            </ModalBody>
        </>
    );
};
