import {
    Alert,
    AlertIcon,
    Box,
    ButtonGroup,
    Container,
    GridItem,
    HStack,
    ModalBody,
    Spacer,
    Stack,
    Text,
    Tooltip,
    useBoolean,
} from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FrendLendOffer } from '../../../data-lib/data-model';
import { getFinancingTerms } from '../../../data-lib/dto/bulla-finance-dto';
import { getLoanOfferAcceptedEvents } from '../../../data-lib/dto/event-filters';
import { addressEquality } from '../../../data-lib/ethereum';
import { NETWORKS } from '../../../data-lib/networks';
import { useCanChangeNetwork } from '../../../hooks/useCanChangeNetwork';
import { useCloseSpecificItem } from '../../../hooks/useClaimDetailDisclosure';
import { useFrendLend } from '../../../hooks/useFrendLend';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { useDataReadiness } from '../../../hooks/useUserData';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { useOnboard, useWeb3 } from '../../../hooks/useWeb3';
import { WithSkeleton } from '../../base/skeleton';
import { getStatusBadge } from '../../base/status-badge';
import { ChainSymbol } from '../../chain-symbol';
import { IPFSLink } from '../../inputs/attachment-input';
import { BullaBlueTextButton, OrangeButton, SecondaryButton, TextButton } from '../../inputs/buttons';
import { CloseModalButton, ModalFooterWithShadow } from '../common';
import { defaultFinancingTermsLabels, getFinancingTermLabels, TermsSummary } from '../financing-terms-modal/financing-inputs';
import { claimDetailVariants, CreditorDebtorBox, ItemDesc, logVariants } from './item-details-components';
import { ViewLogModal } from './view-log';

export const OfferDetails = ({ offer, handleClose }: { offer?: FrendLendOffer; handleClose: () => void }) => {
    const userAddress = useActingWalletAddress();
    const {
        connectedNetwork,
        connectedNetworkConfig: { frendlendAddress, label },
    } = useWeb3();
    const canChangeNetwork = useCanChangeNetwork();
    const { isChainInitialized } = useDataReadiness();
    const isMobile = useIsMobile();

    const [{ loanState, frendLendPending, accepting, rejecting }, { acceptLoan, rejectLoan, validateCreditorState }] = useFrendLend();
    const closeSpecificItem = useCloseSpecificItem();

    const isLoading = !offer || !isChainInitialized(offer.chainId);
    const isUserRelatedToItem = !isLoading && (addressEquality(userAddress, offer.creditor) || addressEquality(userAddress, offer.debtor));

    const [viewLogs, setViewLogs] = useBoolean(false);
    const direction = !!offer ? (addressEquality(userAddress, offer.debtor) ? 'In' : 'Out') : 'not-loaded';

    const navigate = useNavigate();

    useEffect(() => {
        if (frendlendAddress && offer) validateCreditorState(frendlendAddress, offer);
    }, [JSON.stringify(offer), frendlendAddress]);

    const offerTerms = offer
        ? getFinancingTermLabels(
              getFinancingTerms(offer.loanAmount, 0, offer.interestBPS, offer.termLength),
              offer.tokenInfo.token.symbol,
              offer.tokenInfo.token.decimals,
          )
        : defaultFinancingTermsLabels;

    const { changeNetwork } = useOnboard();

    const buttonGroup = (
        <HStack spacing="4" w="100%" justify="center" minH={'50px'}>
            <ButtonGroup spacing="4">
                <BullaBlueTextButton textDecoration={'none'} onClick={setViewLogs.toggle} minW="80px">
                    {viewLogs ? 'Back' : 'View Logs'}
                </BullaBlueTextButton>
            </ButtonGroup>
            {!isMobile && <Spacer />}
            {!!offer && offer.status == 'Offered' && isUserRelatedToItem && (
                <>
                    {
                        <SecondaryButton
                            isLoading={rejecting}
                            onClick={() =>
                                rejectLoan(offer.loanId).then(({ success }) => {
                                    if (success) closeSpecificItem('loanOffer', offer.loanId);
                                })
                            }
                            textDecoration="none"
                            paddingLeft="16px"
                            paddingRight="16px"
                            isDisabled={frendLendPending || offer.chainId !== connectedNetwork}
                        >
                            {direction == 'In' ? 'Reject Loan' : 'Rescind Loan'}
                        </SecondaryButton>
                    }
                    {direction == 'In' && (
                        <Tooltip
                            placement="top"
                            label={
                                loanState === 'insufficient-funds'
                                    ? 'Creditor has insufficient funds to initiate a loan'
                                    : loanState === 'under-approved'
                                    ? 'Creditor has insufficient ERC20 approval to initiate a loan'
                                    : ''
                            }
                        >
                            <HStack>
                                <OrangeButton
                                    isLoading={accepting || loanState === 'init'}
                                    onClick={() =>
                                        acceptLoan(offer.loanId).then(({ success, transactionResult }) => {
                                            if (success && transactionResult) {
                                                const [acceptedEvent] = getLoanOfferAcceptedEvents(transactionResult.events);
                                                if (acceptedEvent) navigate('/financing');
                                            }
                                        })
                                    }
                                    isDisabled={frendLendPending || offer.chainId !== connectedNetwork || loanState !== 'ready'}
                                    minW={'140px'}
                                >
                                    {loanState === 'insufficient-funds' || loanState == 'under-approved'
                                        ? 'Cannot Accept Loan'
                                        : loanState === 'ready'
                                        ? 'Accept Loan'
                                        : ''}
                                </OrangeButton>
                            </HStack>
                        </Tooltip>
                    )}
                </>
            )}
        </HStack>
    );

    const wrongNetworkLabel = offer && offer.chainId !== connectedNetwork && offer.status === 'Offered' && (
        <Container w="fit-content" py="0">
            <Alert status="warning" bg={'white'} py="0">
                <AlertIcon />
                <span>
                    You are connected to {label} network.{' '}
                    {canChangeNetwork ? (
                        <TextButton onClick={() => changeNetwork(offer.chainId)}>{`Switch to ${NETWORKS[offer.chainId].label}`}</TextButton>
                    ) : (
                        `Switch to ${NETWORKS[offer.chainId].label}`
                    )}
                    to {direction == 'In' ? 'accept loan' : 'rescind'}.
                </span>
            </Alert>
        </Container>
    );

    return (
        <>
            <CloseModalButton onClose={handleClose} />
            <ModalBody px="0" overflowY="auto" overflowX={'hidden'} pb="10">
                <Box width={'200%'}>
                    <AnimatePresence initial={false}>
                        <motion.div
                            key="details"
                            style={{
                                width: `50%`,
                                display: 'inline-block',
                                float: 'left',
                                padding: '0 2em',
                                maxHeight: 'fit-content',
                                height: '100%',
                            }}
                            initial={'active'}
                            animate={viewLogs ? 'inactive' : 'active'}
                            transition={{
                                x: { type: 'just' },
                            }}
                            variants={claimDetailVariants}
                        >
                            <WithSkeleton isLoading={isLoading} fixedWidth="16em" height="32px">
                                <Text color="gray.700" fontWeight={'700'} fontSize="24px" noOfLines={1} lineHeight="32px">
                                    Loan Offer Details
                                </Text>
                            </WithSkeleton>
                            <Box h="16px" />
                            <Stack spacing="3">
                                <ItemDesc title="Chain">
                                    <WithSkeleton isLoading={isLoading} fixedWidth="10em">
                                        {offer && (
                                            <HStack>
                                                <Text>{NETWORKS[offer.chainId].label}</Text>
                                                <ChainSymbol chainId={offer.chainId} />
                                            </HStack>
                                        )}
                                    </WithSkeleton>
                                </ItemDesc>

                                <ItemDesc title="Offer Id">
                                    <WithSkeleton isLoading={isLoading} fixedWidth="6em">
                                        <HStack>{offer && <Text> #{offer.loanId} </Text>}</HStack>
                                    </WithSkeleton>
                                </ItemDesc>

                                <ItemDesc title="Description">
                                    <WithSkeleton children={offer ? offer.description : 'loading'} isLoading={isLoading} randomW />
                                </ItemDesc>

                                {!!offer && offer.ipfsHash !== '' && (
                                    <ItemDesc title="File">
                                        <WithSkeleton children={<IPFSLink ipfsHash={offer.ipfsHash} />} isLoading={isLoading} randomW />
                                    </ItemDesc>
                                )}

                                <ItemDesc title="Status">
                                    <WithSkeleton
                                        children={
                                            <GridItem>
                                                {offer && direction !== 'not-loaded' && getStatusBadge(offer.status, direction)}
                                            </GridItem>
                                        }
                                        isLoading={isLoading}
                                        randomW
                                    />
                                </ItemDesc>
                            </Stack>
                            <Box h="2" />
                            <CreditorDebtorBox
                                creditor={offer?.creditor ?? 'loading'}
                                debtor={offer?.debtor ?? 'loading'}
                                chainId={offer?.chainId ?? 1}
                            />
                            <Box h="2" />
                            <WithSkeleton isLoading={isLoading}>
                                <TermsSummary terms={offerTerms} hideDownPaymentRow />
                            </WithSkeleton>
                            <Box h="6" />
                        </motion.div>
                        <motion.div
                            style={{
                                width: `50%`,
                                display: 'inline-block',
                                float: 'left',
                                padding: '0 2em',
                                maxHeight: 'fit-content',
                            }}
                            key="logs"
                            initial={['invisible', 'out']}
                            animate={viewLogs ? ['visible', 'in'] : ['invisible', 'out']}
                            transition={{
                                x: { type: 'just' },
                            }}
                            variants={logVariants}
                        >
                            {offer && <ViewLogModal item={offer} />}
                        </motion.div>
                    </AnimatePresence>
                </Box>
            </ModalBody>
            <ModalFooterWithShadow {...(isMobile ? { px: '0' } : {})}>
                {isMobile ? (
                    <Stack w="100%" spacing={4}>
                        {buttonGroup}
                        {wrongNetworkLabel}
                    </Stack>
                ) : (
                    <Stack w="100%" spacing={4} alignItems="center">
                        {buttonGroup}
                        {wrongNetworkLabel}
                    </Stack>
                )}
            </ModalFooterWithShadow>
        </>
    );
};
