import { ChevronDownIcon, ChevronUpIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import {
    Box,
    BoxProps,
    chakra,
    Checkbox,
    Divider,
    Flex,
    Grid,
    GridItem,
    HStack,
    Skeleton,
    Spacer,
    Stack,
    StyleProps,
    Text,
    TextProps,
    Tooltip,
} from '@chakra-ui/react';
import React from 'react';
import { useIsMobile } from '../../hooks/useIsMobile';
import { SelectableOptions } from '../../hooks/useSelection';
import { toUSD } from '../../tools/common';
import { ChakraCompose } from '../../tools/types';
import { ActionPopover, ThreeDotsButton } from '../display/actionPopover';

export const CHAIN_COLUMN_WIDTH = 'auto';
export const TOKEN_COLUMN_WIDTH = 'auto';
export const DATE_COLUMN_WIDTH = 'auto';
export const USD_MARK_COLUMN_WIDTH = 'auto';
export const FROM_TO_COLUMN_WIDTH = 'auto';
export const STATUS_COLUMN_WIDTH = 'auto';
export const DESCRIPTION_COLUMN_WIDTH = '220px';
export const CATEGORIES_COLUMN_WIDTH = '180px';
export const VIEW_COLUMN_WIDTH = 'auto';
export const AMOUNT_COLUMN_WIDTH = 'auto';
export const CR_DR_COLUMN_WIDTH = 'auto';
export const LINK_COLUMN_WIDTH = '400px';

export const shadowProps = {
    bg: 'scheme.light',
    borderRadius: 'lg',
    p: '4',
    style: { filter: 'drop-shadow(0px 4px 24px #e4e8ec)' },
    border: '1px solid #EAECF0',
};

export const ShadowBox: React.FC<BoxProps & { underlyingBoxRef?: React.Ref<HTMLDivElement> }> = ({ underlyingBoxRef, ...props }) => (
    <Box {...shadowProps} {...props} ref={underlyingBoxRef} />
);

type TwoLineTextWithLabelProps = { children: React.ReactNode & string } & Partial<TextProps>;
export const TwoLineTextWithLabel = ({ children, ...props }: TwoLineTextWithLabelProps) => (
    <Tooltip label={children}>
        <Text textOverflow={'ellipsis'} noOfLines={2} {...props}>
            {children}
        </Text>
    </Tooltip>
);

export type InfoSnippetCardProps = {
    title: string;
    value: number;
    isLoading: boolean;
    sign?: 'positive' | 'negative';
    subtitle: React.ReactNode;
    isCurrency?: boolean;
} & ChakraCompose;

export type Selectable = { selected: boolean };

export const InfoSnippetCard = ({ title, value, subtitle, children, isLoading, sign, isCurrency, ...overrides }: InfoSnippetCardProps) => {
    const [dollars, cents] = toUSD(value).split('.');
    const isMobile = useIsMobile();

    return (
        <Box w="100%" h={isMobile ? '100%' : '40'} {...shadowProps}>
            <Stack w="100%">
                <b>{title}</b>
                <Skeleton isLoaded={!isLoading} w={isLoading ? '40%' : '100%'}>
                    {isCurrency ? (
                        <HStack align={'baseline'} spacing="0">
                            {value !== 0 && (
                                <chakra.span alignSelf={'center'} fontSize={'20px'}>
                                    {sign === 'positive' ? '' : sign === 'negative' && '-'}
                                </chakra.span>
                            )}
                            <chakra.span textStyle="dollarDisplay" as={isMobile ? 'b' : undefined}>
                                {dollars}
                            </chakra.span>
                            <chakra.span textStyle={isMobile ? 'dollarDisplay' : 'centsDisplay'} as={isMobile ? 'b' : undefined}>
                                .{cents}
                            </chakra.span>
                        </HStack>
                    ) : (
                        <Text textStyle="labelXl">{value}</Text>
                    )}
                </Skeleton>
                {!isMobile && subtitle}
            </Stack>
        </Box>
    );
};

export type SortDirection = 'asc' | 'desc';

export type ColumnDefinition = {
    label: string;
    tooltip?: string;
    relativeColumnWidth?: string;
    sortOptions?: { direction?: SortDirection; onSort: VoidFunction };
};

export type ListItemProps = {
    columnValues: (React.ReactNode | string)[];
    actions?: React.ReactNode[];
    selectId?: string;
    isUnsafe?: boolean;
};
export type ListViewCardProps = {
    titleElement?: string | React.ReactNode;
    headers: (string | ColumnDefinition)[];
    displayedListItems: ListItemProps[];
    emptyMessage?: React.ReactNode;
    selectOptions?: SelectableOptions<string>;
    totalItemCount?: number;
    bordered?: false;
    minHeight?: string;
    flexHeight?: true;
    rowSizeOverride?: string;
    alternateRowColor?: string;
    showOverflowX?: boolean;
    removeBorderRadius?: boolean;
};

const getLabelFromColumnDefinition = (header: ListViewCardProps['headers'][number]): string =>
    typeof header === 'string' ? header : header.label;

const getRelativeColumnWidthFromDefinition = (header: ListViewCardProps['headers'][number]): string =>
    typeof header === 'string' || !header.relativeColumnWidth ? '1fr' : header.relativeColumnWidth;

export const ListViewCard = ({
    titleElement,
    headers,
    displayedListItems,
    emptyMessage,
    selectOptions,
    totalItemCount,
    bordered,
    minHeight,
    flexHeight,
    rowSizeOverride,
    alternateRowColor,
    showOverflowX,
    removeBorderRadius,
    ...props
}: ListViewCardProps & BoxProps) => {
    const itemsAreHiddenByFilter = !!totalItemCount && totalItemCount > 0;
    const itemsAreShown = displayedListItems?.length > 0;
    const selectOptionsAreDefined = selectOptions !== undefined;
    const selectable = selectOptionsAreDefined && (itemsAreShown || itemsAreHiddenByFilter);
    const borderProps = bordered ?? true ? shadowProps : undefined;
    const templateColumnCss = `${selectable ? '40px ' : ''}${headers.map(getRelativeColumnWidthFromDefinition).join(' ')}`;
    const parentFlexProps: Partial<StyleProps> = flexHeight ? { display: 'flex', flexDirection: 'column', flex: '1' } : {};
    const isMobile = useIsMobile();

    return (
        <Box {...parentFlexProps} {...props}>
            {titleElement && (
                <Box mb="6" p="0">
                    {titleElement}
                </Box>
            )}

            <Box {...borderProps} data-testid="bullaList" {...parentFlexProps} {...(removeBorderRadius ? { borderRadius: 0 } : {})} p="0">
                <Box overflowX={isMobile || showOverflowX ? 'auto' : 'hidden'}>
                    <Grid
                        templateColumns={templateColumnCss}
                        templateRows={`4em repeat(${displayedListItems.length}, ${rowSizeOverride ?? '4em'})`}
                    >
                        {selectable && (
                            <GridItem key="select all" colSpan={1} pos="relative" py="2">
                                {!displayedListItems.length && <Divider pos="absolute" bottom="-2px" bg={'#EAECF0'} />}
                                <HStack>
                                    <Flex align="center" h="100%" py="2" w="100%" pl="2">
                                        <Checkbox
                                            m="2"
                                            size="lg"
                                            pr="4"
                                            isChecked={selectOptions.isAllSelected}
                                            onChange={e => selectOptions.toggleSelectAll()}
                                        />
                                    </Flex>
                                </HStack>
                            </GridItem>
                        )}
                        {headers.map((header, i) => {
                            const headerLabel = getLabelFromColumnDefinition(header);
                            const columnDef = typeof header === 'object' ? header : null;

                            return (
                                <GridItem key={headerLabel + i} colSpan={1} pos="relative" py="2">
                                    {!displayedListItems.length && <Divider pos="absolute" bottom="-2px" bg={'#EAECF0'} />}
                                    <HStack spacing="1">
                                        <Flex align="center" h="100%" py="2" w="100%" pl="2">
                                            <Text textStyle="columnName">{headerLabel}</Text>
                                            {columnDef?.sortOptions && (
                                                <Box onClick={columnDef.sortOptions.onSort} cursor="pointer" ml="1">
                                                    {columnDef.sortOptions.direction === 'asc' ? (
                                                        <ChevronUpIcon />
                                                    ) : columnDef.sortOptions.direction === 'desc' ? (
                                                        <ChevronDownIcon />
                                                    ) : (
                                                        <ChevronDownIcon color={'gray.400'} />
                                                    )}
                                                </Box>
                                            )}
                                            {columnDef?.tooltip && (
                                                <Tooltip label={columnDef.tooltip}>
                                                    <InfoOutlineIcon boxSize={4} ml="1" />
                                                </Tooltip>
                                            )}
                                        </Flex>
                                    </HStack>
                                </GridItem>
                            );
                        })}

                        {displayedListItems.length ? (
                            displayedListItems.map(({ columnValues, actions, selectId, isUnsafe }, i) => {
                                const rowColor = alternateRowColor && i % 2 !== 0 ? alternateRowColor : 'transparent';

                                const columns = columnValues.map((value: React.ReactNode | string, i: number) => (
                                    <GridItem key={i} colSpan={1} pos="relative" bg={rowColor}>
                                        <Divider pos="absolute" top="0" />
                                        <HStack h="100%" pt="0" bg={isUnsafe ? 'gray.100' : 'transparent'} opacity={isUnsafe ? 0.4 : 1}>
                                            <Flex align="center" h="100%" py="2" px="2" w="100%" pl="4">
                                                {value}
                                            </Flex>
                                            <Spacer />
                                            <Flex>
                                                {i === columnValues.length - 1 && actions?.length && (
                                                    <ActionPopover
                                                        actions={actions}
                                                        triggerElement={
                                                            <a>
                                                                <ThreeDotsButton mr={'4'} />
                                                            </a>
                                                        }
                                                    />
                                                )}
                                            </Flex>
                                        </HStack>
                                    </GridItem>
                                ));

                                return selectable && !!selectId
                                    ? [
                                          <GridItem key={`select-${i}`} colSpan={1} pos="relative" bg={rowColor}>
                                              <Divider pos="absolute" top="0" />
                                              <HStack pt="0" pl="2">
                                                  <Checkbox
                                                      m="2"
                                                      mt="6"
                                                      size="lg"
                                                      pr="4"
                                                      isChecked={selectOptions.isItemSelected(selectId)}
                                                      onChange={e => selectOptions.toggleSelected(selectId)}
                                                  />
                                              </HStack>
                                          </GridItem>,
                                      ].concat(columns)
                                    : columns;
                            })
                        ) : (
                            <GridItem colSpan={selectable ? headers.length + 1 : headers.length}>
                                <Box h="100%">
                                    <Box
                                        m="auto"
                                        width="fit-content"
                                        verticalAlign={'center'}
                                        alignItems={'center'}
                                        alignContent={'center'}
                                        textAlign="center"
                                        py="12"
                                    >
                                        {emptyMessage}
                                    </Box>
                                </Box>
                            </GridItem>
                        )}
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};
