import { Center, Flex, HStack, IconButton, Spinner, Stack } from '@chakra-ui/react';
import React, { useRef } from 'react';
import { OrangeButton } from '../../../components/inputs/buttons';
import { MyLinkModal } from './my-link-modal';
import { LinksFilter } from '../../../components/display/views/filters/claim-filter';
import {
    shadowProps,
    DATE_COLUMN_WIDTH,
    FROM_TO_COLUMN_WIDTH,
    ListViewCardProps,
    VIEW_COLUMN_WIDTH,
    AMOUNT_COLUMN_WIDTH,
    ListViewCard,
    ListItemProps,
    LINK_COLUMN_WIDTH,
} from '../../../components/layout/cards';
import { LinkInformation, useLinks } from '../../../hooks/useLinksApi';
import { ChainId } from '../../../data-lib/networks';
import { TokenInfoByChainIdAndAddress, useTokenRepo } from '../../../hooks/useTokenRepo';
import { CopyableTextLabel } from '../../../components/base/address-label';
import { QRViewerModalNew } from '../../../components/modals/create-claim-modal/qr-viewer-modal';
import { PencilSimple, QrCode, Trash } from 'phosphor-react';
import { usePagination } from '../../../hooks/usePagination';
import { PageSelector } from '../../../components/display/claim-table';

const LINKS_HEADERS: ListViewCardProps['headers'] = [
    { label: 'link name', relativeColumnWidth: FROM_TO_COLUMN_WIDTH },
    { label: 'date created', relativeColumnWidth: DATE_COLUMN_WIDTH },
    { label: 'amount collected', relativeColumnWidth: AMOUNT_COLUMN_WIDTH },
    { label: 'link', relativeColumnWidth: LINK_COLUMN_WIDTH },
    { label: '', relativeColumnWidth: VIEW_COLUMN_WIDTH },
];

const parseLink = (linkInfo: LinkInformation, getTokenByChainIdAndAddress: TokenInfoByChainIdAndAddress) => {
    const urlObj = new URL(linkInfo.link);
    const params = new URLSearchParams(urlObj.hash.substring(2));

    const tokenAddress = params.get('token') as string;
    const chainId = Number(params.get('network')) as ChainId;

    const tokenSymbol = getTokenByChainIdAndAddress(chainId as ChainId)(tokenAddress)?.token.symbol;

    const date = new Date(Number(params.get('date')));

    return {
        name: params.get('name') || 'Unnamed Link',
        date: date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }),
        amount: params.get('amount') ? `${params.get('amount')} ${tokenSymbol}` : 'Not specified',
        url: linkInfo.link,
    };
};

export const MyLinksPageNew = () => {
    const [isLinkModalOpen, setIsLinkModalOpen] = React.useState(false);
    const onLinkModalClose = () => setIsLinkModalOpen(false);
    const listViewCardRef = useRef<HTMLDivElement>(null);
    const { links, isLoading, refetch } = useLinks();
    const { getTokenByChainIdAndAddress } = useTokenRepo();

    const displayedListItems: ListItemProps[] = [...links]
        .sort((a, b) => {
            const aParams = new URLSearchParams(new URL(a.link).hash.substring(2));
            const bParams = new URLSearchParams(new URL(b.link).hash.substring(2));
            const aDate = Number(aParams.get('date'));
            const bDate = Number(bParams.get('date'));
            return bDate - aDate;
        })
        .map(link => {
            const parsedLink = parseLink(link, getTokenByChainIdAndAddress);
            return {
                columnValues: [
                    parsedLink.name.toString(),
                    parsedLink.date.toString(),
                    parsedLink.amount.toString(),
                    <HStack spacing="0.5" overflow="hidden">
                        <CopyableTextLabel fontWeight={600} color="#14282D" displayValue={parsedLink.url.toString()}>
                            {parsedLink.url.toString()}
                        </CopyableTextLabel>
                        <QRViewerModalNew
                            triggerElement={onOpen => (
                                <IconButton
                                    variant="ghost"
                                    borderRadius={'full'}
                                    aria-label="QR code"
                                    icon={<QrCode size={16} color="#14282D" />}
                                    onClick={onOpen}
                                />
                            )}
                            url={parsedLink.url.toString()}
                        />
                    </HStack>,
                    <HStack justifyContent="flex-end" width="100%" height="100%" display="flex" alignItems="center">
                        <IconButton
                            variant="ghost"
                            borderRadius={'full'}
                            aria-label="Delete link"
                            icon={<Trash size={16} color="#14282D" />}
                            onClick={() => null}
                        />

                        <IconButton
                            variant="ghost"
                            borderRadius={'full'}
                            aria-label="Edit link"
                            icon={<PencilSimple size={16} color="#14282D" />}
                            onClick={() => null}
                        />
                    </HStack>,
                ],
                linkId: link.linkId,
            };
        });

    const pageSelectorProps = usePagination(displayedListItems);
    const visibleItems = pageSelectorProps.shownItems;

    return (
        <Stack spacing="8">
            <HStack justifyContent={'space-between'} alignItems={'center'}>
                <LinksFilter filters={{ search: undefined }} setFilters={() => {}} searchPlaceholder="Links" />
                <OrangeButton onClick={() => setIsLinkModalOpen(true)}>Create new link</OrangeButton>
            </HStack>

            <Flex {...shadowProps} direction={'column'} flex="1" overflowX="auto" overflow={'visible'} ref={listViewCardRef}>
                {isLoading ? (
                    <Center h="500px" w="100%">
                        <Spinner />
                    </Center>
                ) : (
                    <>
                        <ListViewCard
                            headers={LINKS_HEADERS}
                            displayedListItems={visibleItems}
                            bordered={false}
                            totalItemCount={displayedListItems.length}
                            emptyMessage="No links to display"
                        />
                        <PageSelector {...pageSelectorProps} justifySelf="center" pt="6" />
                    </>
                )}
            </Flex>

            <MyLinkModal isOpen={isLinkModalOpen} onClose={onLinkModalClose} onSuccess={refetch} />
        </Stack>
    );
};
