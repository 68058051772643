import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
    Alert,
    AlertIcon,
    Box,
    ButtonGroup,
    Grid,
    GridItem,
    HStack,
    Link,
    ModalBody,
    Spacer,
    Stack,
    Text,
    useBoolean,
} from '@chakra-ui/react';
import { formatUnits } from 'ethers/lib/utils';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useMemo } from 'react';
import { WarningIcon } from '../../assets/warning';
import { getInstantPaymentEvents } from '../../data-lib/dto/event-filters';
import { getUniqueEventId } from '../../data-lib/dto/events-dto';
import { addressEquality, weiToDisplayAmt } from '../../data-lib/ethereum';
import { cacheSafeAddress, getGnosisSafeURL } from '../../data-lib/gnosis-tools/gnosis';
import { getItemRelationToUser } from '../../data-lib/helpers';
import { NETWORKS } from '../../data-lib/networks';
import { useCanChangeNetwork } from '../../hooks/useCanChangeNetwork';
import { useTokenBalances } from '../../hooks/useChainData';
import { useEditNotesDisplay } from '../../hooks/useEditNotesDisplay';
import { useExternalTransactionsApi } from '../../hooks/useExternalTransactionsApi';
import { useInstantPayment } from '../../hooks/useInstantPayment';
import { useIsMobile } from '../../hooks/useIsMobile';
import { OffchainInvoiceInfo } from '../../hooks/useOffchainInvoiceFactory';
import { useCancelOffchainInvoice, useEditOffchainInvoiceMetadata, usePaidOffchainInvoiceCallback } from '../../hooks/useOffchainInvoices';
import { useDataReadiness, useGlobalUserData } from '../../hooks/useUserData';
import { useActingWalletAddress } from '../../hooks/useWalletAddress';
import { useOnboard, useWeb3 } from '../../hooks/useWeb3';
import { useAppState } from '../../state/app-state';
import { useGnosisSafe } from '../../state/gnosis-state';
import { calculateDateDifference, toDateDisplay, toDateWithTime } from '../../tools/common';
import { AlertInfo } from '../base/alert';
import { WithSkeleton } from '../base/skeleton';
import { getStatusBadge } from '../base/status-badge';
import { useBullaToast } from '../base/toast';
import { ChainSymbol } from '../chain-symbol';
import { TokenAmount } from '../currency/token-display-amount';
import { InstantPaymentButton } from '../display/claim-action-buttons';
import { ViewTagOnItem } from '../inputs/account-tag-input';
import { BullaBlueTextButton, SecondaryButton, TextButton } from '../inputs/buttons';
import { CloseModalButton, ModalFooterWithShadow, ShareItemButton } from './common';
import { disabledInputProps, WalletSelector } from './create-claim-modal/create-claim-inputs';
import { PastPaymentsTable } from './create-claim-modal/create-link-handler';
import { claimDetailVariants, CreditorDebtorBox, ItemDesc, logVariants, ReceiptLine } from './item-details-modal/item-details-components';
import { ViewLogModal } from './item-details-modal/view-log';

type OffchainInvoiceDetailsProps = {
    invoice: OffchainInvoiceInfo;
    handleClose: () => void;
    modalContentRef?: React.RefObject<HTMLDivElement>;
};

export const OffchainInvoiceDetails = ({ invoice, handleClose, modalContentRef }: OffchainInvoiceDetailsProps) => {
    const actingWallet = useActingWalletAddress();
    const { connectedNetwork, connectedNetworkConfig, userAddress } = useWeb3();
    const { isChainInitialized } = useDataReadiness();
    const canChangeNetwork = useCanChangeNetwork();
    const { pendingPayments, safeInfo, userSafes, fetchSafeAppInfo } = useGnosisSafe();
    const safesLoaded = typeof userSafes === 'object';
    const sortedSafeArrayById = useMemo(() => {
        return safesLoaded
            ? Object.entries(userSafes)
                  .map(([chainId, safeCaches]) => ({
                      chainId: +chainId,
                      safes: Object.entries(safeCaches)
                          .map(([safeAddress, otherStuff]) => ({ ...otherStuff, safeAddress, retry: false }))
                          .sort((a_safeInfo, b_safeInfo) => (b_safeInfo?.lastUsed ?? 0) - (a_safeInfo?.lastUsed ?? -1)),
                  }))
                  .filter(({ safes }) => safes.length !== 0)
                  .filter(({ chainId }) => chainId === connectedNetwork)
            : [];
    }, [safesLoaded, userSafes, connectedNetwork]);

    const isMobile = useIsMobile();
    const tokenBalances = useTokenBalances({ chainId: invoice.chainId, poll: true });
    const bodyRef = React.useRef<HTMLDivElement>(null);
    const [creatingInstantPayment, { createInstantPayment }] = useInstantPayment();
    const paidOffchainInvoiceCallback = usePaidOffchainInvoiceCallback();
    const { getAttachmentUriForInvoice } = useExternalTransactionsApi();
    const cancelInvoice = useCancelOffchainInvoice();
    const editInvoiceMetadata = useEditOffchainInvoiceMetadata();
    const [canceling, setCanceling] = React.useState(false);
    const triggerToast = useBullaToast();
    const [savingTags, setSavingTags] = useBoolean();
    const [viewLogs, setViewLogs] = React.useState(false);
    const toggleViewLogs = () => setViewLogs(viewLogs => !viewLogs);
    const [_isLoading, setIsLoading] = React.useState(false);

    const isLoading = !isChainInitialized(invoice.chainId) || _isLoading;
    const isPendingGnosisApproval = !!pendingPayments.payClaimTransactions.find(item =>
        item.__type === 'PayClaimTransaction' ? item.tokenId === invoice?.id : true,
    ); // TODO: Pending gnosis approval
    const direction = getItemRelationToUser(actingWallet, invoice).direction;
    const isPayable = invoice.claimStatus === 'Pending' && !addressEquality(invoice.creditor, actingWallet);
    const { labelRow, notesRow, modalOpen, closeModal } = useEditNotesDisplay({
        isLoading,
        item: { ...invoice, initialNotes: invoice.notes }, // tags and description are not managed by backend
    });

    const { changeNetwork } = useOnboard();
    const { readyToTransact } = useAppState();
    const { paidBullaItemsWithPayments } = useGlobalUserData('exclude-originating-claims');
    const pastPaymentsToRecipient = paidBullaItemsWithPayments.filter(p => addressEquality(p.creditor, invoice.creditor)).slice(0, 2);
    const hasTransactedBefore = pastPaymentsToRecipient.length > 0;
    const templateColumns = isMobile ? '1fr 1fr' : '1fr 1fr 1fr';
    const notYourInvoice = !addressEquality(actingWallet, invoice.debtor) && !addressEquality(actingWallet, invoice.creditor);

    const claimAmount = formatUnits(invoice.claimAmount, invoice.tokenInfo.token.decimals);
    const payInvoice = React.useCallback(() => {
        createInstantPayment({
            ...invoice,
            claimAmount,
            instantPayment: true,
            recipient: invoice.creditor,
            token: invoice.tokenInfo.token,
            description: invoice.id,
        }).then(result => {
            if (result) {
                const paymentEvents = getInstantPaymentEvents(result.events);
                const id =
                    paymentEvents.length > 0
                        ? getUniqueEventId({ txHash: paymentEvents[0].txHash, logIndex: paymentEvents[0].logIndex })
                        : undefined;

                id && paidOffchainInvoiceCallback(invoice.id, id, invoice.chainId);
                handleClose();
            }
        });
    }, []);

    const wrongNetwork = invoice && invoice.chainId !== connectedNetwork;
    const buttonGroup = (
        <HStack spacing="4" w="100%" justify="center" minH={'50px'}>
            {addressEquality(actingWallet, invoice.creditor) && (
                <HStack>
                    <ButtonGroup spacing="4">
                        <BullaBlueTextButton textDecoration={'none'} onClick={toggleViewLogs} minW="80px">
                            {viewLogs ? 'Back' : 'View Logs'}
                        </BullaBlueTextButton>
                    </ButtonGroup>
                    {invoice.status.kind === 'Pending' && (
                        <SecondaryButton
                            isLoading={isLoading || canceling}
                            isDisabled={creatingInstantPayment || canceling}
                            onClick={() => {
                                setCanceling(true);
                                cancelInvoice(invoice.id)
                                    .then(success => {
                                        if (success) {
                                            triggerToast({
                                                title: 'Invoice rescinded successfully.',
                                                position: 'top',
                                                variant: 'top-accent',
                                            });
                                            handleClose();
                                        } else {
                                            triggerToast({ title: 'Failed to rescind invoice.', position: 'top', status: 'error' });
                                        }
                                    })
                                    .finally(() => setCanceling(false));
                            }}
                        >
                            Rescind
                        </SecondaryButton>
                    )}
                </HStack>
            )}
            {!isMobile && <Spacer />}
            {isPayable && (
                <InstantPaymentButton
                    paymentInfo={[{ amount: claimAmount, token: invoice.tokenInfo.token }]}
                    isLoading={isLoading || creatingInstantPayment}
                    isDisabled={creatingInstantPayment || wrongNetwork || canceling}
                    onClick={payInvoice}
                />
            )}
        </HStack>
    );

    const outstandingAmount = +invoice.claimAmount.sub(invoice.paidAmount);

    const walletBalance = tokenBalances.getBalanceForToken(invoice.tokenInfo.token.address);

    const insufficientFunds = (walletBalance: number) =>
        weiToDisplayAmt({ amountWei: outstandingAmount, token: invoice.tokenInfo.token }) > walletBalance;

    const switchText = `Switch to ${NETWORKS[invoice.chainId].label}`;

    const wrongNetworkLabel = wrongNetwork && (
        <Box p="0">
            <Alert status="warning" bg={'white'} py="0">
                <AlertIcon />
                <span>
                    You are connected to {connectedNetworkConfig.label} network.{' '}
                    {canChangeNetwork ? <TextButton onClick={() => changeNetwork(invoice.chainId)}>{switchText}</TextButton> : switchText}{' '}
                    to {direction === 'Out' ? 'Pay' : 'Rescind'}.
                </span>
            </Alert>
        </Box>
    );
    const usageLabel = React.useMemo(() => (invoice ? invoice.claimType : 'Invoice'), [invoice]);
    const isUnpaidUnknownInvoice = useMemo(
        () => invoice.debtor == '' && invoice.description == '' && !addressEquality(invoice.creditor, actingWallet),
        [invoice.debtor, invoice.description],
    );

    const changeWallet = (selectedSafeAddress: string) => {
        setIsLoading(true);
        fetchSafeAppInfo(selectedSafeAddress);
        setIsLoading(false);
        cacheSafeAddress(connectedNetwork, userAddress, selectedSafeAddress);
    };

    return (
        <>
            <CloseModalButton onClose={handleClose} />
            <ModalBody ref={bodyRef} px="0" overflowY="auto" overflowX={'hidden'} pb="10">
                <Box width="200%">
                    <AnimatePresence initial={false}>
                        <motion.div
                            key="details"
                            style={{
                                width: `50%`,
                                display: 'inline-block',
                                float: 'left',
                                padding: '0 2em',
                                maxHeight: 'fit-content',
                                height: '100%',
                            }}
                            initial={'active'}
                            animate={viewLogs ? 'inactive' : 'active'}
                            transition={{
                                x: { type: 'just' },
                            }}
                            variants={claimDetailVariants}
                        >
                            <WithSkeleton isLoading={isLoading} fixedWidth="16em" height="32px">
                                <Text color="gray.700" fontWeight={'700'} fontSize="24px" noOfLines={1} lineHeight="32px">
                                    {usageLabel} Details
                                    <ShareItemButton item={invoice} />
                                </Text>
                            </WithSkeleton>
                            <Box h="16px" />
                            {isPendingGnosisApproval && (
                                <Alert status="info" mb="4">
                                    <AlertIcon />
                                    {`${usageLabel} payment is pending approval in your Gnosis-Safe`}
                                    <Link href={getGnosisSafeURL(connectedNetworkConfig, safeInfo!.safeAddress)} isExternal ml="2" mt="1">
                                        <Box color={'scheme.accent_dark'}>
                                            <ExternalLinkIcon mt="-4px" />
                                        </Box>
                                    </Link>
                                </Alert>
                            )}

                            <Stack spacing="3">
                                {isUnpaidUnknownInvoice &&
                                    (hasTransactedBefore ? (
                                        <AlertInfo
                                            message={
                                                <HStack>
                                                    <Text>
                                                        Due to privacy reasons, some invoice information may be withheld from this screen.
                                                        Invoice details are found in the invoice email.
                                                    </Text>
                                                </HStack>
                                            }
                                        />
                                    ) : (
                                        <HStack
                                            backgroundColor={'#FFF3DC'}
                                            border="2px"
                                            borderColor={'#FFD98B'}
                                            borderRadius={8}
                                            px={5}
                                            py={3}
                                        >
                                            <WarningIcon mr="2" color="#B54708" />
                                            <Text fontSize="14px" textAlign="left" color="#B54708" fontWeight={'500'}>
                                                You have not transacted with this wallet before. Please confirm the recipient address to
                                                prevent loss of funds.
                                            </Text>
                                        </HStack>
                                    ))}
                                <ItemDesc title="Chain">
                                    <WithSkeleton isLoading={isLoading} fixedWidth="10em">
                                        <HStack>
                                            <Text>{NETWORKS[invoice.chainId].label}</Text>
                                            <ChainSymbol chainId={invoice.chainId} />
                                        </HStack>
                                    </WithSkeleton>
                                </ItemDesc>

                                <ItemDesc title="Created">
                                    <WithSkeleton isLoading={isLoading} fixedWidth="10em">
                                        {`${toDateWithTime(invoice.created)} (${calculateDateDifference(invoice.created)})`}
                                    </WithSkeleton>
                                </ItemDesc>

                                <ItemDesc title="Due Date">
                                    <WithSkeleton isLoading={isLoading} fixedWidth="10em">
                                        {`${toDateDisplay(invoice.dueBy)} (Due ${calculateDateDifference(invoice.dueBy)})`}
                                    </WithSkeleton>
                                </ItemDesc>

                                <ItemDesc title="Invoice Id">
                                    <WithSkeleton isLoading={isLoading} fixedWidth="6em">
                                        <HStack>
                                            <Text>{invoice.id}</Text>
                                        </HStack>
                                    </WithSkeleton>
                                </ItemDesc>
                                {invoice.description !== '' && (
                                    <ItemDesc title="Description">
                                        <WithSkeleton children={invoice.description} isLoading={isLoading} randomW />
                                    </ItemDesc>
                                )}
                                {invoice.file && (
                                    <ItemDesc title="File">
                                        <WithSkeleton
                                            children={
                                                <Link
                                                    color="brand.bulla_blue"
                                                    href={getAttachmentUriForInvoice(invoice.id)}
                                                    mr="2"
                                                    fontWeight={700}
                                                    fontSize="14px"
                                                    textDecor={'none'}
                                                >
                                                    {invoice.file.name} <ExternalLinkIcon ml="2px" mt={'-2px'} />
                                                </Link>
                                            }
                                            isLoading={isLoading}
                                            randomW
                                        />
                                    </ItemDesc>
                                )}
                                {!notYourInvoice && (
                                    <ItemDesc title="Categories">
                                        <WithSkeleton isLoading={isLoading} fixedWidth="14em">
                                            {invoice ? (
                                                <ViewTagOnItem
                                                    item={invoice}
                                                    isLoading={savingTags}
                                                    isDisabled={savingTags}
                                                    dropdownPortalRef={modalContentRef}
                                                    saveTagsOverride={async newTags => {
                                                        setSavingTags.on();

                                                        return editInvoiceMetadata({
                                                            [invoice.id]: {
                                                                tags: newTags,
                                                            },
                                                        })
                                                            .then(result => {
                                                                result
                                                                    ? triggerToast({
                                                                          title: 'Tags saves successfully.',
                                                                      })
                                                                    : triggerToast({
                                                                          title: 'Error saving categories. Please try again later.',
                                                                          status: 'error',
                                                                      });
                                                            })
                                                            .finally(() => {
                                                                setSavingTags.off();
                                                            });
                                                    }}
                                                />
                                            ) : (
                                                'loading'
                                            )}
                                        </WithSkeleton>
                                    </ItemDesc>
                                )}
                                <ItemDesc title="Status">
                                    <WithSkeleton
                                        children={<GridItem>{getStatusBadge(invoice.claimStatus, direction)}</GridItem>}
                                        isLoading={isLoading}
                                        randomW
                                    />
                                </ItemDesc>
                            </Stack>
                            <Box h="2" />
                            <CreditorDebtorBox
                                creditor={invoice.creditor}
                                debtor={!!invoice.debtor ? invoice.debtor : undefined}
                                chainId={invoice.chainId}
                            />
                            {!notYourInvoice && (
                                <>
                                    {labelRow}
                                    {notesRow}
                                </>
                            )}
                            {hasTransactedBefore && invoice.claimStatus == 'Pending' && !addressEquality(invoice.creditor, actingWallet) && (
                                <>
                                    <Box h="4" />
                                    <PastPaymentsTable
                                        payments={pastPaymentsToRecipient}
                                        readyToTransact={readyToTransact}
                                        network={invoice.chainId}
                                        recipient={invoice.creditor}
                                        buttonLabel="View creditor activity"
                                    />
                                </>
                            )}
                            {invoice.claimStatus == 'Pending' && !addressEquality(invoice.creditor, actingWallet) && (
                                <Stack mt="6">
                                    <Box fontWeight="500" lineHeight="20px" fontSize="16px" color="gray.700">
                                        <Text>You’re paying with wallet:</Text>
                                    </Box>
                                    <WalletSelector
                                        isDisabled={isLoading || pendingPayments.pendingInstantPaymentInfos.length > 0}
                                        selectableWallets={[
                                            ...sortedSafeArrayById.map(safe => safe.safes.map(s => s.safeAddress)).flat(),
                                            userAddress,
                                        ].filter(
                                            (address, index, self) =>
                                                self.indexOf(address) === index && !addressEquality(address, actingWallet),
                                        )}
                                        w="100%"
                                        textAlign={'left'}
                                        onWalletSelected={changeWallet}
                                        {...disabledInputProps}
                                    />
                                </Stack>
                            )}
                            <Grid templateColumns={templateColumns} w={'100%'} rowGap="4" mt="8">
                                <ReceiptLine
                                    isLoading={isLoading}
                                    isMobile={!!isMobile}
                                    title={'Requested'}
                                    tokenAmount={
                                        <WithSkeleton isLoading={isLoading}>
                                            {!isLoading && (
                                                <HStack justifyContent="end">
                                                    <TokenAmount amount={invoice.claimAmount} tokenInfo={invoice.tokenInfo} />
                                                </HStack>
                                            )}
                                        </WithSkeleton>
                                    }
                                />

                                <ReceiptLine
                                    isLoading={isLoading}
                                    isMobile={!!isMobile}
                                    title={'Received'}
                                    tokenAmount={
                                        <WithSkeleton isLoading={isLoading}>
                                            <TokenAmount amount={invoice.paidAmount} tokenInfo={invoice.tokenInfo} />
                                        </WithSkeleton>
                                    }
                                />
                                {invoice.claimStatus == 'Pending' &&
                                    direction === 'Out' &&
                                    !addressEquality(invoice.creditor, actingWallet) && (
                                        <ReceiptLine
                                            isLoading={isLoading}
                                            isMobile={!!isMobile}
                                            title="Wallet Balance"
                                            tokenAmount={
                                                <WithSkeleton isLoading={walletBalance == undefined}>
                                                    {walletBalance !== undefined && (
                                                        <TokenAmount
                                                            amount={walletBalance}
                                                            tokenInfo={invoice.tokenInfo}
                                                            isDisplayAmount
                                                            withRounding
                                                        />
                                                    )}
                                                </WithSkeleton>
                                            }
                                            {...(walletBalance !== undefined && insufficientFunds(walletBalance)
                                                ? { color: 'red.500' }
                                                : {})}
                                        />
                                    )}
                                <ReceiptLine
                                    isLoading={isLoading}
                                    isMobile={!!isMobile}
                                    title={'Outstanding'}
                                    tokenAmount={
                                        <WithSkeleton isLoading={isLoading}>
                                            <HStack justifyContent="end" h="24px">
                                                {<TokenAmount amount={outstandingAmount} tokenInfo={invoice.tokenInfo} />}
                                            </HStack>
                                        </WithSkeleton>
                                    }
                                    {...(walletBalance !== undefined && insufficientFunds(walletBalance) && direction == 'Out'
                                        ? { color: 'red.500' }
                                        : {})}
                                />
                            </Grid>
                        </motion.div>
                        <motion.div
                            style={{
                                width: `50%`,
                                display: 'inline-block',
                                float: 'left',
                                padding: '0 2em',
                                maxHeight: 'fit-content',
                            }}
                            key="logs"
                            initial={['invisible', 'out']}
                            animate={viewLogs ? ['visible', 'in'] : ['invisible', 'out']}
                            transition={{
                                x: { type: 'just' },
                            }}
                            variants={logVariants}
                        >
                            {invoice && <ViewLogModal item={invoice} />}
                        </motion.div>
                    </AnimatePresence>
                </Box>
            </ModalBody>
            <ModalFooterWithShadow {...(isMobile ? { px: '0' } : {})}>
                <Stack w="100%" spacing={4} {...(!isMobile ? { alignItems: 'center' } : {})}>
                    {buttonGroup}
                    {wrongNetworkLabel}
                </Stack>
            </ModalFooterWithShadow>
        </>
    );
};
